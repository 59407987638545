<template>
  <div>
    <b-modal
      id="modal-view-bonus-detail"
      cancel-variant="outline-secondary"
      centered
      size="lg"
      title="Detail Bonus"
      >
      <b-overlay :show="show" variant="transparent" no-wrap />

      <b-table-simple
        responsive
        bordered
        table-class="table-narrow table-white-space table-highlight"
        class="mb-2"
      >
        <b-tbody>
          <b-tr>
            <b-td class="table-td-header">{{ $t("Rollover") }}</b-td>
            <b-td>{{ bonusShowDetail.rollover }}</b-td>
            <b-td class="table-td-header">{{ $t("Ip") }}</b-td>
            <b-td>{{ bonusShowDetail.ip }}</b-td>
          </b-tr>
          <b-tr>
            <b-td class="table-td-header">{{ $t("Amounts") }}</b-td>
            <b-td>{{ numberFormat(bonusShowDetail.amount /1000) }}</b-td>
            <b-td class="table-td-header">{{ $t("Amount Bonus") }}</b-td>
            <b-td>{{ numberFormat(bonusShowDetail.amount_bonus /1000) }}</b-td>
          </b-tr>
          <b-tr>
            <b-td class="table-td-header">{{ $t("Info Rollover Amount") }}</b-td>
            <b-td v-if="bonusShowDetail.info_rollover">{{ sumMountArray(bonusShowDetail.info_rollover) }}</b-td>
            <b-td class="table-td-header">{{ $t("Info Rollover Count") }}</b-td>
            <b-td v-if="bonusShowDetail.info_rollover">{{ sumCountArray(bonusShowDetail.info_rollover) }}</b-td>
          </b-tr>
          <b-tr>
            <b-td class="table-td-header">{{ $t("Số tiền cược còn lại") }}</b-td>
            <b-td v-if="numberFormat(bonusShowDetail.remain_bet_amount) < 0">0</b-td>
            <b-td v-else-if="numberFormat(bonusShowDetail.remain_bet_amount) >= 0">
              {{ bonusShowDetail.remain_bet_amount }}
            </b-td>
            <b-td class="table-td-header">{{ $t("Số vòng cược còn lại") }}</b-td>
            <b-td v-if="bonusShowDetail.rollover - sumCountArray(bonusShowDetail.info_rollover) < 0">0</b-td>
            <b-td v-else-if="bonusShowDetail.rollover - sumCountArray(bonusShowDetail.info_rollover) >= 0">{{ bonusShowDetail.rollover - sumCountArray(bonusShowDetail.info_rollover) }}</b-td>
          </b-tr>
          <b-tr>
            <b-td class="table-td-header">{{ $t("Status") }}</b-td>
            <b-td>{{ bonusShowDetail.statusName }}</b-td>
            <b-td class="table-td-header">{{ $t("Used") }}</b-td>
            <b-td>{{ bonusShowDetail.used }}</b-td>
          </b-tr>
          <b-tr>
            <b-td class="table-td-header">{{ $t("Time Start") }}</b-td>
            <b-td>{{ bonusShowDetail.time_start }}</b-td>
            <b-td class="table-td-header">{{ $t("Expired At") }}</b-td>
            <b-td>{{ bonusShowDetail.expired_at }}</b-td>
          </b-tr>

              <b-tr>
            <b-td class="table-td-header">{{ $t("Is Expired") }}</b-td>
            <b-td>{{ bonusShowDetail.is_expired }}</b-td>
            <b-td class="table-td-header">{{ $t("Reject Reason") }}</b-td>
            <b-td>{{ bonusShowDetail.reject_reason }}</b-td>
          </b-tr>
          <b-tr>
            <b-td class="table-td-header">{{ $t("Made By") }}</b-td>
            <b-td>{{ bonusShowDetail.made_by }}</b-td>
            <b-td class="table-td-header">{{ $t("Made Time") }}</b-td>
            <b-td>{{ bonusShowDetail.made_time }}</b-td>
          </b-tr>
          <b-tr>
            <b-td class="table-td-header">{{ $t("Void By") }}</b-td>
            <b-td>{{ bonusShowDetail.void_by }}</b-td>
            <b-td class="table-td-header">{{ $t("Void Time") }}</b-td>
            <b-td>{{ bonusShowDetail.void_time }}</b-td>
          </b-tr>
          <b-tr>
            <b-td class="table-td-header">{{ $t("Created At") }}</b-td>
            <b-td>{{ bonusShowDetail.created_at }}</b-td>
            <b-td class="table-td-header">{{ $t("Updated At") }}</b-td>
            <b-td>{{ bonusShowDetail.updated_at }}</b-td>
          </b-tr>
          <b-tr>
            <b-td class="table-td-header">{{ $t("Số tiền cược yêu cầu") }}</b-td>
            <b-td>{{ bonusShowDetail.require_bet_amount }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import {
  BOverlay,
  BImg,
  BFormSpinbutton,
  BFormRadio,
  BLink,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  VBModal,
  BInputGroupAppend,
  BFormTextarea,
  BBadge,
  BFormFile,
  BTableSimple,
  BTr,
  BTd,
  BTh,
  BTbody,
  BTable,
} from "bootstrap-vue";
import { numberFormat } from '@core/utils/filter'

import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";

export default {
  components: {
    BOverlay,
    BImg,
    BFormSpinbutton,
    BFormRadio,
    BLink,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BModal,
    BFormTextarea,
    BBadge,
    BFormFile,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BTableSimple,
    BTr,
    BTd,
    BTh,
    BTbody,
    BTable,
    // quillEditor,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    bonusDetail: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      bonusShowDetail: {
        rollover: "",
        ip: "",
        status: "",
        used: "",
        amount: "",
        amount_bonus: "",
        time_start: "",
        expired_at: "",
        is_expired: "",
        reject_reason: "",
        made_by: "",
        made_time: "",
        void_by: "",
        void_time: "",
        created_at: "",
        updated_at: "",
        info_rollover_amount: "",
        info_rollover_count: "",
        require_bet_amount: 0,
        remain_bet_amount: 0
      },
      show: false,
    };
  },
  watch: {
    async bonusDetail(newVal) {
      if (newVal) {
        this.show = true;
        await this.fillBonusNewMemberData(newVal);
        this.show = false;
      }
    },
  },
  methods: {
    async fillBonusNewMemberData(data) {
      await store
        .dispatch("bonus/fetchBonusCodesDetail", {
          bonus_code_id: data.id,
        })
        .then((response) => {
          if (response.data.code === "00") {
            let item = response.data.data;
            let sum = 0;
            let newArray = item.info_rollover?.map(function (val) {
              return Number(val.amount);
            });
            newArray?.map(function (value) {
              sum += value;
            });
            this.bonusShowDetail = {
              ...item,
              statusName: item.status === 1
                  ? 'Pending'
                  : item.status === 2
                      ? 'Success'
                      : item.status === 3
                          ? 'Reject'
                          : 'Cancel',
              used: item.used === 1 ? 'Yes' : 'No',
              is_expired: item.is_expired === 1 ? 'True' : 'False',
              require_bet_amount: numberFormat((
                  (parseFloat(item.amount) + parseFloat(item.amount_bonus)) / 1000
              ) * item.rollover),
              remain_bet_amount: numberFormat(
                  ((parseFloat(item.amount) + parseFloat(item.amount_bonus)) / 1000) * item.rollover - sum/1000)
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    },
  
  },
  setup() {
    const sumCountArray = (array) => {
      let sum = 0;
      let newArray = array?.map(function (val) {
        return Number(val.count);
      });
      newArray?.map(function (value) {
        sum += value;
      });
      return numberFormat(sum);
    };
    const sumMountArray = (array) => {
      let sum = 0;
      let newArray = array?.map(function (val) {
        return Number(val.amount);
      });
      newArray?.map(function (value) {
        sum += value;
      });
      return numberFormat(sum / 1000);
    };
    return {numberFormat,sumMountArray,sumCountArray};
  },
};
</script>
<style lang="scss">
// @import '@core/scss/vue/libs/quill.scss';
.dark-layout .table-highlight .table-td-header {
    background-color: #343d55 !important;
}
.table-highlight td.table-td-header {
    background-color: #f3f2f7;
}
</style>
